import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: '',
  templateUrl: './main-component.html',
  styleUrls: [
    './main-component.css']
})
export class MainComponent {
  title = 'Zaczarowany Ołówek';
  hoverColor = 'rgb(0,101,169)';
  initialColor = 'black';

  ngOnInit(){
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
  }
}
