import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as galleries from '../../../api/galleries.json';
import { Gallery } from 'angular-gallery';
import { IGallery } from '../../core/models/gallery/IGallery';

@Component({
  templateUrl: './exact-gallery-component.html',
  styleUrls: ['./exact-gallery-component.css']
})

export class GalleryPageComponent implements OnInit {
  galleryList: any = (galleries as any).default;
  galleryName: string;
  galleryObject: IGallery;
  paths: Array<string>;
  thumbnailPaths: any;
  pathsAsset: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private gallery: Gallery
  ) {
    this.galleryName = '';
    this.galleryObject = {} as IGallery;
    this.paths = [];
  }
  hoverColor = 'rgb(217,17,128)';
  initialColor = 'white';
  backgroundColor = '#f18d6b';

  ngOnInit(): void {
    this.galleryObject = this.getGallery();
    this.galleryName = this.galleryObject.name;
    this.paths = this.galleryObject.paths;
    this.thumbnailPaths = this.galleryObject.thumbnails.slice();
    this.paths.forEach((el) => {
      this.pathsAsset.push({ path: el });
    });
    window.onorientationchange = () => {
      window.location.reload();
    };
  }

  getGallery(): IGallery {
    let id = this.route.snapshot.paramMap.get('id');
    let arr = this.galleryList.filter((el: { id: string | null; }) => el.id == id);
    return (arr[0]);
  }

  showGallery(index: number): void {
    const prop = {
      images: [
        ...this.pathsAsset.slice()
      ],
      index
    };
    this.gallery.load(prop);
  }

}