import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './features/about-us/about-us-component';
import { ContactComponent } from './features/contact/contact-component';
import { AllGalleriesComponent } from './features/all-galleries/all-galleries-component';
import { GalleryPageComponent } from './features/exact-gallery/exact-gallery-component';
import { MainComponent } from './features/main/main-component';
import { OfferComponent } from './features/offer/offer-component';
import { RecruitmentComponent } from './features/recruitment/recruitment-component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'about_us', component: AboutUsComponent },
  { path: 'about_us/:section', component: AboutUsComponent },
  { path: 'main', component: MainComponent },
  { path: 'offer', component: OfferComponent },
  { path: 'offer/:section', component: OfferComponent },
  { path: 'recruitment', component: RecruitmentComponent },
  { path: 'gallery', component: AllGalleriesComponent },
  { path: 'gallery/:id', component: GalleryPageComponent },
  { path: 'contact', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
