import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MobileService } from '../../services/mobile.service';

declare var $: any;
const colorLogoPath = '/assets/gfx/glowna/logo-color.png';
const whiteLogoPath = '/assets/gfx/glowna/logo-white.png';
const whiteHamburgerPath = '/assets/gfx/shared/hamburger.png';
const blackHamburgerPath = '/assets/gfx/shared/hamburger-black.png';

@Component({
  selector: 'navbar',
  templateUrl: './navbar-component.html',
  styleUrls: ['./navbar-component.css'],
  styles: [

  ]
})

export class NavbarComponent {
  @Input() hoverColor: any;
  @Input() initialColor: any;
  @Input() backgroundColor: any;
  @Output() expand = new EventEmitter<any>();
  logoPath = '';
  hamburgerPath = '';
  expanded = false;
  isMobileDevice: boolean;

  constructor(
    private isMobileService: MobileService

  ) {
    this.isMobileDevice = false;
  }

  ngOnInit() {
    this.getDeviceInfo();
    this.expanded = !this.expanded;
    if (this.initialColor === 'white') {
      this.logoPath = whiteLogoPath;
      this.hamburgerPath = whiteHamburgerPath;
      $('.navbar-toggler-icon').addClass('whiteHamburger');
    } else {
      this.logoPath = colorLogoPath;
      this.hamburgerPath = blackHamburgerPath;
      $('.navbar-toggler-icon').addClass('blackHamburger');
      $('#mainPageButton').addClass('animatedNavBrand');
    }
    $('nav a').css('color', this.initialColor);
    $('nav a').mouseover((e: any) => {
      $(e.target).css('color', this.hoverColor);
    });
    $('nav a').mouseout((e: any) => {
      $(e.target).css('color', this.initialColor);
    });
    $('#myNav').css('background-color', this.backgroundColor);
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      $('.collapse').removeClass('show');
      $('body').css({ 'overflow': 'visible' });
      $('body').css({ 'height': 'initial' });
      $('#myNav').removeClass('slide-left');
      $('#myNav').addClass('slide-right');
      $('#content').removeClass('fade-out');
      $('#content').addClass('fade-in');
      $('#footer').css('display', 'block');
    } else {
      $('.collapse').removeClass('show');
      $('body').css({ 'overflow': 'hidden' });
      $('#myNav').removeClass('slide-right');
      $('#myNav').addClass('slide-left');
      $('#content').removeClass('fade-in');
      $('#content').addClass('fade-out');
      $('#footer').css('display', 'none');
    }
  }

  getDeviceInfo(): void {
    this.isMobileDevice = this.isMobileService.getDeviceInfo();
  }
}
