import { Pipe, PipeTransform, ElementRef} from '@angular/core';

@Pipe({ name: 'lazy'})
export class lazyLoadPipe implements PipeTransform  {
  constructor( nativeElement: ElementRef<HTMLImageElement>) {}
  supports = 'loading' in HTMLImageElement.prototype;
  transform(nativeElement:any) {
    if (this.supports)
    return nativeElement.setAttribute('loading', 'lazy');
  }
}