<div class="container" id="footer">
  <div class="row">
    <div class="col-md-5 text-left" style="line-height: 28px;">
      <p>PRZYDATNE LINKI
      <br>
      <a href="https://www.wielka-wies.pl" target="_blank">Gmina Wielka Wieś www.wielka-wies.pl</a>
      <br>
      <a href="https://www.gokis.wielka-wies.pl" target="_blank">GOKIS www.gokis.wielka-wies.pl</a>
      <br>
      <a href="https://www.kuratorium.krakow.pl" target="_blank">Kuratorium Oświaty</a>
      </p>
    </div>
    <div class="col-md-6" style="line-height: 26px;">
      <p>PLIKI COOKIES</p>
      <p>Używamy plików cookies aby ułatwić Ci korzystanie z naszego serwisu oraz do celów
        statystycznych. Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie
        w pamięci urządzenia. Pamiętaj, że możesz samodzielnie zarządzać cookies, zmieniając
        ustawienia przeglądarki.
      </p>
    </div>
  </div>
</div>