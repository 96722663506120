import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LazyImgDirective } from './directives/LazyImgDirective';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { lazyLoadPipe } from './pipes/lazyLoading.pipe';

@NgModule({
  declarations: [
    LazyImgDirective,
    SafeHtmlPipe,
    lazyLoadPipe
  ],
  imports: [BrowserModule],
  providers: [],
  bootstrap: [],
  exports: [SafeHtmlPipe, LazyImgDirective, lazyLoadPipe]
})

export class SharedModule {

}
