import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../core/services/mobile.service';

declare var $: any;

@Component({
  templateUrl: './contact-component.html',
  styleUrls: ['./contact-component.css']
})

export class ContactComponent implements OnInit {
  content: string;
  contentList: Array<any>;
  contentTitle = '';
  hoverColor = 'rgb(0,109,161)';
  initialColor = 'white';
  backgroundColor = '#c0a0ff';
  isMobileDevice: boolean;
  backgroundImages: string;

  constructor(
    private isMobileService: MobileService
  ) {
    this.content = '';
    this.contentList = [
      {
        name: 'contact',
        displayName: 'kontakt',
        text: `<p><strong>Niepubliczne Przedszkole „Zaczarowany Ołówek”</strong><br>
            ul. Orlich Gniazd 30, 32-085 Giebułtów, gmina Wielka-Wieś<br>
            Numer konta Alior Bank 46 2490 0005 0000 4600 5782 2656</p>
            <p><strong>Dyrektor przedszkola: mgr Patrycja Francuz</strong><br>
            Tel. 534 641 527<br>
            Tel. 509 437 835</p>

            <p><strong>www.zaczarowany.com</strong><br>
            Napisz do nas: przedszkole@zaczarowany.com<br>
            fb: <a href="https://www.facebook.com/Przedszkole-Zaczarowany-Ołówek-322801157813715"
              target="_blank">Przedszkole „Zaczarowany Ołówek”</a></p>`
      }
    ];
    this.isMobileDevice = false;
    this.backgroundImages = '';
  }

  ngOnInit() {
    this.setBackground();
    this.content = this.contentList[0].text;
    this.contentTitle = this.contentList[0].displayName;
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
  }

  changeContent(section: string): void {
    const text = this.contentList.find(x => x.name === section).text;
    this.content = text;
  }

  getDeviceInfo(): void {
    this.isMobileDevice = this.isMobileService.getDeviceInfo();
  }

  setBackground(): void {
    this.getDeviceInfo();
    if (this.isMobileDevice === true) {
      this.backgroundImages = '';
    } else {
      this.backgroundImages = `<img src="../../assets/gfx/kontakt/man1.png" id="img17" loading="lazy">
        <img src="../../assets/gfx/kontakt/pink.png" id="img18" loading="lazy">
        <img src="../../assets/gfx/kontakt/man2.png" id="img19" loading="lazy">`;
    }
  }
}
