<div id="wrap">
    <navbar id="navbar" [hoverColor]="hoverColor" [initialColor]="initialColor"></navbar>
    <img src="../../assets/gfx/rekr/pinksplash.png" id="img10">
    <img src="../../assets/gfx/rekr/circle2.png" id="img11">
    <div class="bgimages" [innerHtml]="backgroundImages | safeHtml">
    </div>

    <div class="bg"></div>

    <div class="row container-fluid" id="content">
        <p [innerHtml]="contentTitle | safeHtml" id="contentTitle" class="col-12"></p>
        <div class="" id="menu">
            <ul class="" id="menuList">
                <li>
                    <a (click)="changeContent('recruitment')" class="text-right">rekrutacja</a>
                </li>
            </ul>
        </div>
        <div class="" id="aspect">
            <p [innerHtml]="content"></p>
        </div>
    </div>
</div>
<footer id="footer" class="py-3"></footer>