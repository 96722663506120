import { Component } from '@angular/core';
import { MobileService } from '../../core/services/mobile.service';

declare var $: any;
@Component({
  templateUrl: './recruitment-component.html',
  styleUrls: ['./recruitment-component.css']
})

export class RecruitmentComponent {
  content: string;
  contentList: Array<any>;
  hoverColor = 'rgb(217,17,128)';
  initialColor = 'white';
  backgroundColor = '#ff8800';
  contentTitle = '';
  isMobileDevice: boolean;
  backgroundImages: string;

  constructor(
    private isMobileService: MobileService
  ) {
    this.content = '';
    this.contentList = [
      {
        name: 'recruitment',
        displayName: 'rekrutacja',
        text: `<p>Nabór do przedszkola „Zaczarowany ołówek” odbywa się przez cały
            rok, w zależności od ilości wolnych miejsc i według kolejności zgłoszenia.</p>
          <p><strong>Co zrobić, żeby zapisać dziecko do przedszkola?</strong>
          <br>
          W pierwszej kolejności prosimy o kontakt z przedszkolem. Otrzymacie Państwo kartę zapisu.
          <br>
          Wypełnioną kartę prosimy dostarczyć do przedszkola w godz. od 7:30 do
            17:00, ewentualnie można ją również wysłać elektronicznie na adres:
             przedszkole@zaczarowany.com</p>
          <p>Zapraszamy do zadawania pytań dotyczących spraw organizacyjnych i zapisów:
          <br>
          <strong>Kontakt:</strong>
          <br>
          tel. 534641527
          <br>
          e-mail: przedszkole@zaczarowany.com`
      }
    ];
    this.backgroundImages = '';
    this.isMobileDevice = false;
  }

  ngOnInit() {
    this.setBackground();
    this.content = this.contentList[0].text;
    this.contentTitle = this.contentList[0].displayName;
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
  }

  changeContent(section: string): void {
    const text = this.contentList.find(x => x.name === section).text;
    this.content = text;
  }

  getDeviceInfo(): void {
    this.isMobileDevice = this.isMobileService.getDeviceInfo();
  }

  setBackground(): void {
    this.getDeviceInfo();
    if (this.isMobileDevice === true) {
      this.backgroundImages = '';
    } else {
      this.backgroundImages = `<img src="../../assets/gfx/rekr/pink.png" id="img12">
        <img src="../../assets/gfx/rekr/house.png" id="img13">
        <img src="../../assets/gfx/rekr/pink.png" id="img14">
        <img src="../../assets/gfx/rekr/pinksplash.png" id="img15">
        <img src="../../assets/gfx/rekr/circle.png" id="img16">`;
    }
  }

}