<div id="wrap">
    <navbar id="navbar" [hoverColor]="hoverColor" [initialColor]="initialColor"></navbar>
    <div class="bgimages" [innerHtml]="backgroundImages | safeHtml"></div>
    <div class="row container-fluid offset-mt-3" id="content">
        <p [innerHtml]="contentTitle | safeHtml" id="contentTitle"></p>
        <div class="" id="menu">
            <ul class="" id="menuList">
                <li>
                    <a (click)="changeContent('contact')">kontakt</a>
                </li>
            </ul>
        </div>
        <div class="" id="aspect">
            <p [innerHtml]="content"></p>
        </div>
    </div>
</div>
<div class="bg"></div>
<footer id="footer" class="py-3"></footer>