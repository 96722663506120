<navbar id="navbar" [hoverColor]="hoverColor" [initialColor]="initialColor">
</navbar>
<div id="wrap">
    <div id="content" class="container-fluid">
        <div class="row img-container">
            <div class="col-12" id="galleryTitle">
                <h1 [innerHtml]="galleryName" class="text-left"></h1>
            </div>
            <div id="thumbnails" class="row col-12 d-flex justify-content-center">
                <div *ngFor="let path of thumbnailPaths; let i = index"
                        (click)="showGallery(i)"
                        class="col-xl-3 col-lg-3 col-md-12 mb-4 imgItem m-3"
                        style="background-image: url({{thumbnailPaths[i]}}); ">
                    <!-- <img src="{{path}}" (click)="showGallery(i) " class="img-fluid img-box "> -->
                </div>
            </div>
        </div>
    </div>
    <div class="bg"></div>
</div>
<footer id="footer" class="py-3 "></footer>