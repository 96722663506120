import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MobileService {
  private messageSource = new BehaviorSubject('');
  currentMessage = isMobile();
  constructor() { }

  changeMessage(message: string): void {
    this.messageSource.next(message);
  }

  getDeviceInfo(): boolean {
    return isMobile();
  }
}

const isMobile = () => {
  const ua = navigator.userAgent;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
    return true;
  }
  else if (/Chrome/i.test(ua)){
    return false;
  }
  else {
    return false;
  }
}
