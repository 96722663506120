import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './features/about-us/about-us-component';
import { MainComponent } from './features/main/main-component';
import { NavbarComponent } from './core/components/navbar/navbar-component';
import { FooterComponent } from './core/components/footer/footer-component';
import { OfferComponent } from './features/offer/offer-component';
import { RecruitmentComponent } from './features/recruitment/recruitment-component';
import { AllGalleriesComponent } from './features/all-galleries/all-galleries-component';
import { ContactComponent } from './features/contact/contact-component';
import { GalleryPageComponent } from './features/exact-gallery/exact-gallery-component'; 
import { HttpClientModule } from '@angular/common/http';

import { IvyGalleryModule } from 'angular-gallery';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    MainComponent,
    NavbarComponent,
    FooterComponent,
    OfferComponent,
    RecruitmentComponent,
    AllGalleriesComponent,
    GalleryPageComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyGalleryModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
