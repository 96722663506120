<div id="wrap">
    <navbar id="navbar" [hoverColor]="hoverColor" [initialColor]="initialColor">

    </navbar>

    <div id="content" class="container-fluid">
        <div class="row img-container">
            <div *ngFor="let gallery of galleryList" class="col-xl-4 col-lg-4 col-md-6 mb-4">
                <a routerLink="/gallery/{{gallery.id}}">
                    <div class="imgItem" style="background-image: url(../../assets/gfx/gallery/{{gallery.thumbnails[gallery.thumbnailPhoto]}});">
                    </div>
                    <p class="figcaption" [innerHtml]="gallery.name"></p>
                </a>
            </div>
        </div>
        <div class="bg"></div>
    </div>
</div>
<footer id="footer" class="py-3"></footer>