import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileService } from '../../core/services/mobile.service';
import { UserService } from 'src/app/core/services/user.service';

declare var $: any;
@Component({
  selector: 'about-us',
  templateUrl: './about-us-component.html',
  styleUrls: ['./about-us-component.css'],
})

export class AboutUsComponent implements OnInit {
  content: string;
  contentList: Array<any>;
  contentTitle: any;
  hoverColor = 'rgb(0,109,161)';
  initialColor = 'white';
  backgroundColor = '#40bd7a';
  isMobileDevice: boolean;
  backgroundImages: string;
  exampleData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private isMobileService: MobileService,
    private userService: UserService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.content = '';
    this.contentTitle = '';
    this.isMobileDevice = false;
    this.contentList = [
      {
        name: 'us',
        displayName: 'o nas',
        text: `„Zaczarowany Ołówek” w Giebułtowie to niepubliczne przedszkole o
            profilu artystycznym prowadzone przez stowarzyszenie, zrzeszające
            artystów i pedagogów sztuki.<br>
          Przedszkole mieści się w pięknej, spokojnej i zielonej okolicy,
            tuż koło ogrodzonego placu zabaw i boiska sportowego ze specjalną
            nawierzchnią, dostosowaną do potrzeb najmłodszych.
          W naszej pracy opieramy się na współpracy nauczycieli, rodziców
            i dzieci. Skupiamy się na budowaniu więzi i wspieraniu serdecznych,
            przyjacielskich relacji w społeczności przedszkolnej, kształtowaniu
            dziecięcej samodzielności i rozwijaniu kompetencji społecznych w
            celu uzyskania jak najlepszego przygotowania do szkoły. Jesteśmy
            małą, zgrana grupą i razem tworzymy przyjazną i wspierającą wszystkich
            atmosferę tego miejsca, w której każdy może liczyć na uwagę i
            indywidualne traktowanie.<br>
          Nasze przedszkolaki biorą udział w przyrodniczych wycieczkach
            po okolicy, wyjazdach na „zielone przedszkole”, zwiedzają Kraków
            z przewodnikiem, uczestniczą w cyklach warsztatów podróżniczych,
            konkursach i przedstawieniach. Rodzice biorą czynny udział w życiu
            przedszkola m.in. co roku wspólnie z nauczycielami wystawiają
            sztukę teatralna dla dzieci. Przedszkole zatrudnia psychologa,
            który codziennie pracuje z dziećmi pomagając im w adaptacji,
            wspierając ich rozwój oraz służąc pomocą rodzicom. Korzystamy
            z wielu metod wspierających rozwój dzieci m. in metody ruchu
            rozwijającego Weroniki Sherborne. Dzielimy się też naszymi
            pasjami sportowymi, zwłaszcza do sportów biegowych.<br>
          Nasi wychowankowie są laureatami wielu nagród, między innymi
            otrzymaliśmy trzykrotnie grand prix za prace plastyczne w
            krakowskim konkursie „Smoczuś” organizowanym przez Akademię
            Żakowska i Wojewódzką Bibliotekę Publiczną w Krakowie oraz
            wyróżnienie za organizację biegu przedszkolaków „Sprintem do
            maratonu”, zorganizowanym przez nas przy współpracy z zawodowymi
            trenerami sportów biegowych (maratonu, triathlonu i biegów na orientacje)<br>
          Stowarzyszenie „Tygart”, które jest organem założycielskim naszego
            przedszkola realizuje również coroczne projekty edukacyjne w szkołach
            na terenie Gminy Wielka Wieś. W ramach projektów nauczyciele z
            „Zaczarowanego Ołówka” prowadzą z dziećmi w klasach I - VIII
            warsztaty plastyczne z elementami historii sztuki.`
      },
      {
        name: 'perssonel',
        displayName: 'kadra',
        text: `<strong>mgr Patrycja Francuz</strong><br>
          <strong>Dyrektor Przedszkola</strong><br>
          <p>Magister sztuki z jedenastoletnim doświadczeniem pracy z dziećmi.
          Ukończyłam Edukację Artystyczną na Wydziale Sztuki Uniwersytetu
          Pedagogicznego im KEN w Krakowie. Prowadzę autorskie warsztaty artystyczne
          dla dzieci oraz młodzieży.</p>
          <br>
          <strong>mgr Anna Rosa-Nurek</strong><br>
          <strong>Nauczyciel – wychowawca grupy przedszkolnej</strong><br>
          <p>Praca z dziećmi jest moją pasją, daje mi wielką radość i satysfakcję.
          Każdy dzień jest nowym wyzwaniem i doświadczeniem. W swojej pracy staram
          się zapewnić dzieciom poczucie bezpieczeństwa, akceptacji i zrozumienia.
          W trakcie zajęć pobudzam dzieci do intelektualnej i twórczej aktywności.</p>
          <br>
          <strong>mgr Katarzyna Hordyńska</strong><br>
          <strong>artysta plastyk, nauczyciel sztuki</strong>
          <p>Prowadzę warsztaty artystyczne Ukończyłam Edukację Artystyczną na Wydziale
          Sztuki Uniwersytetu Pedagogiczny im KEN w Krakowie. Prowadzę autorskie
          warsztaty artystyczne dla dzieci. Praca z dziećmi jest moją pasją, a ich
          entuzjazm i kreatywność inspiruje mnie do tworzenia nowych scenariuszy warsztatów.</p>
          <br>
          <strong>mgr Martyna Piotrowicz</strong><br>
          <strong>logopeda, logopeda WWRD, filolog polski, wkrótce oligofrenopedagog</strong>
          <p>Ukończyłam 5 letnie studia z zakresu logopedii i filologii polskiej, jestem w trakcie
          studiów podyplomowych na kierunku Edukacja i Rehabilitacja Osób z Niepełnosprawnością
          Intelektualną. Biorę udział w szkoleniach, stale się rozwijam i poszerzam swoją wiedzę.
          W swojej pracy uwielbiam zauważać i rozwijać potencjał komunikacyjny i językowy dzieci,
          usprawniać artykulację, uczyć poprzez naturalne sytuacje i zabawę.</p>
          <br>
          <strong>Natalia Medyńska</strong>
          <strong>Psycholog</strong>
          <p>Jestem absolwentką kierunku Psychologia na Uniwersytecie Jagiellońskim. Praca z
          dziećmi od zawsze była moim marzeniem, a obserwacja ich rozwoju i postępów daje mi
          wielką satysfakcję i radość. Doświadczenie i umiejętności rozwijam poprzez codzienne
          spotkania z dziećmi i ich rodzicami w przedszkolu oraz gabinecie. W swojej pracy zawsze
          staram się uwzględnić całe najbliższe otoczenie dziecka, dlatego w sposób szczególny
          cenię sobie współpracę z rodzicami, która jest niezbędna do udzielenia skutecznego wsparcia.</p>
          <br>
          `
      },
      {
        name: 'association',
        displayName: 'stowarzyszenie',
        text: `Stowarzyszenie Tygart zostało założone w roku 1998 w Krakowie.
          Pracujemy w obszarze kultury i sztuki oraz edukacji i promocji zdrowia.
          Łączymy edukację artystyczną z pracą na rzecz włączenia społecznego.
          Stowarzyszenie prowadzi przedszkole artystyczne oraz realizuje projekty
          edukacyjne i artystyczne dla dzieci i dorosłych, a także jest organem
          założycielskim dla poradni zajmujacej sie psychoterapią. Stowarzyszenie
          jest wpisane do rejestru stowarzyszeń, innych organizacji społecznych
          i zawodowych, fundacji oraz publicznych zakładów opieki zdrowotnej
          Krajowego Rejestru Sądowego pod numerem 0000235756`
      },
      {
        name: 'projects',
        displayName: 'projekty',
        text: `Stowarzyszenie Tygart realizuje projekty artystyczne, edukacyjne
          i kulturalne wspierające rozwój społeczności lokalnej. W latach 2011 - 2013
          zrealizowaliśmy projekt „European Modern Painting History” („EMPATHY”),
          finansowany w całości ze środków Komisji Europejskiej w ramach programu
          "Uczenie się przez całe życie". Celem „EMPATHY” było wsparcie dla osób
          starszych, pochodzących z małych miejscowości i mających utrudniony
          dostęp do oferty kulturalnej proponowanej w dużych ośrodkach, oraz
          wymiana doświadczeń pomiędzy europejskimi organizacjami zajmującymi
          się edukacją i pracą na rzecz włączenia społecznego.<br>

          W ramach „EMPATHY” artyści, nauczyciele i uczestnicy projektu spotykali
          się w siedmiu krajach europejskich, aby poznawać kulturę swoich partnerów
          i uczestniczyć w organizowanych wspólnie warsztatach artystycznych. W
          latach 2015 - 2019 stowarzyszenie zrealizowało pięć projektów
          współfinansowanych przez gminę Wielka Wieś w ramach Rocznego Programu
          współpracy samorządu gminy Wielka Wieś z organizacjami pozarządowymi.
          Były to cykle zajęć łączące krótkie wykłady z historii sztuki z warsztatami
          plastycznymi. przeznaczone dla dzieci uczących się w przedszkolach i
          szkołach podstawowych.<br>

          <strong>„Otwieramy niebo” – 2015</strong><br>
          <strong>„Od korzenia po konar” – 2016</strong><br>
          <strong>„Podróż przez barwę i dotyk”- 2017</strong><br>
          <strong>„Inaczej nie znaczy źle” – 2018</strong><br>
          <strong>„Okiełznać żywioł” - 2019</strong><br>
          <p><br></p>
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-md-6 col-12">
                <img src="../../../assets/gfx/o_nas/projektyzdj1.jpg" class="img-fluid">
              </div>
              <div class="col-md-6 col-12 pt-md-0 pt-5">
                <img src="../../../assets/gfx/o_nas/projektyzdj2.jpg" class="img-fluid">
              </div>
            </div>
          </div>`
      },
      {
        name: 'statute',
        displayName: 'statut przedszkola',
        text: `Aktualny statut przedszkola jest dostępny <strong><a href="../../../assets/files/statut.docx" download="statut.docx">tutaj.</a></strong>`
      },
      {
        name: 'report',
        displayName: 'raport z ewaluacji',
        text: `<p>Pod adresem <strong><a href="../../../assets/files/statut.pdf"
            download="statut.pdf">tutaj</a></strong> dostępny jest raport z ewaluacji naszego przedszkola
            wykonanej przez wizytatorów Kuratorium Oświaty w Krakowie w 2014r.</p><br>
          <p>Ewaluacja ma m.in. na celu ustalenie poziomu spełniania przez przedszkole
            wymagań zawartych w rozporządzeniu Ministra Edukacji Narodowej z dnia
            7.10.2009r. wraz ze zmianami z dnia 10.05.2013r.
            Przedszkole może spełniać te wymagania na pięciu poziomach:<br><br>

          Poziom E – oznaczający niski stopień wypełniania wymagania przez przedszkole.<br>
          Poziom D – oznaczający podstawowy stopień wypełniania wymagania przez przedszkole.<br>
          Poziom C – oznaczający średni stopień wypełniania wymagania przez przedszkole.<br>
          Poziom B – oznaczający wysoki stopień wypełniania wymagania przez przedszkole.<br>
          Poziom A – oznaczający bardzo wysoki stopień wypełniania wymagania przez przedszkole.<br>
          Badano trzy obszary:<br><br>

          Procesy wspomagania rozwoju i edukacji dzieci są zorganizowane w sposób
          sprzyjający uczeniu się.<br>
          Dzieci nabywają wiadomości i umiejętności określone w podstawie programowej.<br>
          Respektowane są normy społeczne.<br>
          We wszystkich obszarach nasze przedszkole zostało ocenione na poziom B.`
      },
      {
        name: 'child_protection',
        displayName: 'polityka ochrony dzieci',
        text: `Polityka ochrony dzieci dostępna jest do pobrania <strong><a href="../../../assets/files/polityka_ochrony_dzieci.odt" download="polityka_ochrony_dzieci.odt">tutaj</a></strong>`
      }
    ];
    this.backgroundImages = '';
  }

  ngOnInit() {
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
    this.setBackground();
    const initialSection = this.route.snapshot.paramMap.get('section');
    if (initialSection === null) {
      this.content = this.contentList[0].text;
      this.contentTitle = this.contentList[0].displayName;
    } else {
      const initialContentObject = this.contentList.filter(element => element.name === initialSection);
      this.content = initialContentObject[0].text;
      this.contentTitle = initialContentObject[0].displayName;
    }
  }

  changeContent(section: string, event: any): void {
    $('.selected').removeClass('selected');
    event.target.classList.add('selected');
    window.scroll(0, 0);
    const text = this.contentList.find(x => x.name === section).text;
    const title = this.contentList.find(x => x.name === section).displayName;
    this.contentTitle = title;
    this.content = text;
  }

  getDeviceInfo(): void {
    this.isMobileDevice = this.isMobileService.getDeviceInfo();
  }

  setBackground(): void {
    this.getDeviceInfo();
    if (this.isMobileDevice === true) {
      this.backgroundImages = '';
    } else {
      this.backgroundImages = `<img src="../../assets/gfx/o_nas/circle3.png" id="img2">
        <img src="../../assets/gfx/o_nas/dragon.png" id="img3">
        <img src="../../assets/gfx/o_nas/circle2.png" id="img4">
        <img src="../../assets/gfx/o_nas/circle2.png" id="img5">`;
    }
  }
}
