<div class="bg">
    <img src="../../assets/gfx/glowna/1.png" alt="" id="bgimg1">
    <img src="../../assets/gfx/glowna/3.png" alt="bgimg2" id="bgimg2">
    <img src="../../assets/gfx/glowna/2.png" alt="bgimg3" id="bgimg3">
    <img src="../../assets/gfx/glowna/4.png" alt="bgimg4" id="bgimg4">
    <img src="../../assets/gfx/glowna/5.png" alt="bgimg5" id="bgimg5">
    <img src="../../assets/gfx/glowna/6.png" alt="bgimg6" id="bgimg6">
    <img src="../../assets/gfx/glowna/4.png" alt="bgimg7" id="bgimg7">
    <img src="../../assets/gfx/glowna/2.png" alt="bgimg8" id="bgimg8">
    <img src="../../assets/gfx/glowna/7.png" alt="bgimg9" id="bgimg9">
    <img src="../../assets/gfx/glowna/6.png" alt="bgimg10" id="bgimg10">
    <img src="../../assets/gfx/glowna/8.png" alt="bgimg11" id="bgimg11">
    <img src="../../assets/gfx/glowna/1.png" alt="bgimg12" id="bgimg12">
    <img src="../../assets/gfx/glowna/9.png" alt="bgimg13" id="bgimg13">
    <img src="../../assets/gfx/glowna/10.png" alt="bgimg14" id="bgimg14">
</div>
<div class="bg-mobile">
    <img src="../../assets/gfx/glowna/5mobile.png" id="img1">
    <img src="../../assets/gfx/glowna/8.png" id="img2">
    <img src="../../assets/gfx/glowna/10.png" id="img3">
    <img src="../../assets/gfx/glowna/3.png" id="img4">
</div>

<navbar [hoverColor]="hoverColor" [initialColor]="initialColor"></navbar>
<div class="col-12 col-lg-6 d-flex align-items-center" id="content">
    <div class="" id="mainText">
        <p class="h1">Zaczarowany Ołówek</p>
        <br>
        <p class="h2">artystyczne przedszkole <br>w Giebułtowie</p>
        <br>
        <p class="h5">Jeśli chcesz, żeby twoje dziecko przekraczało próg przedszkola z uśmiechem, wszechstronnie się rozwijało i zaczęło zauważać swoje umiejętności i talenty, które będą wzrastać pod troskliwą opieką wykwalifikowanej kadry, serdecznie zapraszamy do
            zapoznania się z ofertą naszego przedszkola.</p>
    </div>
</div>