<div id="wrap" class="">
    <div class="bgimages" [innerHtml]="backgroundImages | safeHtml">
    </div>
    <navbar id="navbar" [hoverColor]="hoverColor" [initialColor]="initialColor">
    </navbar>

    <div class="row container-fluid" id="content">
        <p [innerHtml]="contentTitle | safeHtml" class="col-10" id="contentTitle"></p>
        <a (click)="changeContent('activities', true)" id="activitiesHeader" class="col-12">nasze zajęcia</a>
        <div class="col-12" id="mobileActivitiesMenu">
            <ul class="list-unstyled col-12" id="activitiesList">
                <li><a (click)="changeContent('judo', true)">judo</a></li>
                <li><a (click)="changeContent('english', true)">język angielski</a></li>
                <li><a (click)="changeContent('artWorkshop', true)">warsztaty plastyczne</a></li>
                <li><a (click)="changeContent('rhythmicity', true)">rytmika</a></li>
                <li><a (click)="changeContent('gymnastics', true)">gimnastyka</a></li>
                <li><a (click)="changeContent('speech', true)">zajęcia logopedyczne</a></li>
                <li><a (click)="changeContent('psychologicalConsultations', true)">konsultacje
                    psychologiczne</a></li>
                <li><a (click)="changeContent('dogotherapy', true)">dogoterapia</a></li>
                <li><a (click)="changeContent('travelWorkshops', true)">warsztaty podróżnicze</a></li>
            </ul>
        </div>
        <div class="" id="menu">
            <ul class="" id="menuList">
                <li>
                    <a (click)="changeContent('offer', false, $event)" class="selected">oferta</a>
                </li>
                <li data-toggle="collapse" class="collapsed-active" data-target="#activities">
                    <a (click)="changeContent('activities', false, $event)">nasze zajęcia</a>
                    <ol class="sub-menu collapse" id="activities">
                        <li><a (click)="changeContent('judo', false, $event)">judo</a></li>
                        <li><a (click)="changeContent('english', false, $event)">język angielski</a></li>
                        <li><a (click)="changeContent('artWorkshop', false, $event)">warsztaty plastyczne</a></li>
                        <li><a (click)="changeContent('rhythmicity', false, $event)">rytmika</a></li>
                        <li><a (click)="changeContent('gymnastics', false, $event)">gimnastyka</a></li>
                        <li><a (click)="changeContent('speech', false, $event)">zajęcia logopedyczne</a></li>
                        <li><a (click)="changeContent('psychologicalConsultations', false, $event)">konsultacje
                            psychologiczne</a></li>
                        <li><a (click)="changeContent('dogotherapy', false, $event)">dogoterapia</a></li>
                        <li><a (click)="changeContent('travelWorkshops', false, $event)">warsztaty podróżnicze</a></li>
                    </ol>
                </li>
                <li>
                    <a (click)="changeContent('schedule', false, $event)">plan zajęć</a>
                </li>
                <li>
                    <a (click)="changeContent('building', false, $event)">budynek</a>
                </li>
                <li>
                    <a (click)="changeContent('priceList', false, $event)">cennik</a>
                </li>
                <li>
                    <a (click)="changeContent('catering', false, $event)">oferta cateringowa</a>
                </li>
            </ul>
        </div>
        <div class="" id="aspect">
            <p [innerHtml]="content | safeHtml"></p>
        </div>
    </div>
</div>
<div class="bg"></div>
<footer id="footer" class="py-3"></footer>