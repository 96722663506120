import { Component } from '@angular/core';
import { MobileService } from '../app/core/services/mobile.service'
import { Subscription } from 'rxjs';

declare var $: any;
declare var isMobile: boolean;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css']
})

export class AppComponent {
  title = 'Zaczarowany Ołówek';

  onActivate(event: Event) {
    window.scroll(0, 0);
    let offsetLeft = $('#menu').width() - $('#menuList').width() +5;
    if ($(window).width() > 1175) $('#menuList').css({ 'left': offsetLeft })
    else $('#menuList').css({ 'left': offsetLeft });
  }
  ngOnInit() {
    $(window).on('resize', () => {
      let offsetLeft = $('#menu').width() - $('#menuList').width() +5;
      if ($(window).width() > 1175){
        $('#menuList').css({ 'left': offsetLeft });
      }
    });

  }
  constructor(){}
}

function viewport() {
  var e: any = window, a: any = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}
