import { Component, OnInit } from '@angular/core';
import * as Galleries from '../../../api/galleries.json';
declare var $: any;
 
@Component({
  templateUrl: './all-galleries-component.html',
  styleUrls: ['./all-galleries-component.css']
})

export class AllGalleriesComponent implements OnInit {
  galleryList: any = (Galleries as any).default.slice();
  hoverColor = 'rgb(217,17,128)';
  initialColor = 'white';
  backgroundColor = '#f18d6b';

  ngOnInit(){
    this.galleryList.reverse();
    $('li').click(() => {
      $('.collapse').collapse('hide');
    });
  }
}
